
































































import Vue from 'vue';

export default Vue.extend({
  computed: {
    externalLink(): string | null {
      const { url } = this.$route.query;

      if (Array.isArray(url)) return null;
      return url;
    },
    url(): URL {
      return new URL(this.externalLink || '');
    },
    hostname(): string {
      return this.url.hostname;
    },
    isHttps(): boolean {
      return this.url.protocol === 'https:';
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.removed) {
      return next('/external-link/removed');
    }

    const { url } = to.query;
    const base = process.env.VUE_APP_BASE_URL || '';

    if (!Array.isArray(url) && url?.startsWith(base)) {
      const [, path] = url.split(base);

      if (path) return next(path);
    }

    return next();
  },
  meta() {
    const title = this.$t('title').toString();

    return {
      title,
      meta: [
        {
          vmID: 'og:title',
          property: 'og:title',
          content: `${title} – Auftrag.app`,
        },
      ],
    };
  },
});
